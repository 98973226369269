@import url('../../../assets/styles/fonts.scss');
body , html {
    height: 100%;
    margin: 0;
    background: url('../../../assets/background/background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.authorization-page{
    color: #4D4D4D;
    font-family: 'Cera Round Pro Regular';
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
        h4{
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            margin-top: 130px;
        }
        p{
            width: 500px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            span{
                font-family: "Cera Round Pro Bold";
            }
        }
    }
    .card{
        width: 600px;
        flex-direction: row;
        background: #FFFFFF;
        box-shadow: 8px 8px 24px 12px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        padding: 40px 20px 40px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: url('../../../assets/background/cardBackground.png');
        background-position: right;
        background-repeat: no-repeat;
        background-color: white;
        height: 320px;
        margin-top: 40px;
        &-title{
            flex-direction: row;
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: black;
            padding-bottom: 20px;
        }
        &-text{
            width: 443px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: justify;
            padding-bottom: 25px;
        }
        .auth-terms {
            .auth-checkbox {
                width: 25px;
                height: 25px;
                display: inline-block;
                vertical-align: middle;
                padding: 0;
                
                border: 1px solid #8A00E6;
                background-color: white;
                opacity: 1;
                margin-right: 20px;
                appearance: none;
                border-radius: 25%
            }
    
            input[type='checkbox']:checked {
                background-color: #81F5D6 !important;
                text-align: center;
                line-height: 25px;
                &::before{
                    font-family: Arial, Helvetica, sans-serif;
                    color: #8A00E6;
                    content: url('../../../assets/img/icon-check.png');
                }
            }
        }
        
        .disclaimer{
            font-family: "Cera Round Pro Bold";
            width: 362px            ;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #6400A9;
            padding-top: 20px;
        }
        label{
            font-size: 14px;
            span{
                font-family: "Cera Round Pro Bold";
            }
        }
       .button-container{
            padding-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            a {
                text-decoration: none !important;
            }

            .primary{
                font-family: "Cera Round Pro Regular";
                display: block;
                width: 289px;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                color: #FFFFFF;
                background-color: #6400a9;
                border-radius: 100px;
                border: 1px;
                border-color: transparent;
                &:hover {
                    background: #81f5d6;
                    color: #8A00E6;
                }
                &:disabled{
                    color: #FFFFFF;
                    background: #BCBCBC;
                }
                &:selection{
                    color: #4D4D4D;
                }
            }
            .secondary{
                font-family: "Cera Round Pro Regular";
                display: block;
                width: 289px;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                color: #4D4D4D;
                border-radius: 100px;
                background: transparent;
                border: none;
                &:hover {
                    color: #7B61FF;
                }
                &:disabled{
                    color: #FFFFFF;
                    background: #BCBCBC;
                }
            }
            .cancel{
                font-family: "Cera Round Pro Regular";
                display: block;
                width: 289px;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                color: #4D4D4D;
                background: transparent;
                border: none;
            }
        }   
    }
}