@font-face {
  font-family: 'Cera Round Pro Bold';
  src: url('../fonts/cera_round_pro_bold-webfont.woff2') format('woff2'),
    url('../fonts/cera_round_pro_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cera Round Pro Black';
  src: url('../fonts/cera_round_pro_black-webfont.woff2') format('woff2'),
    url('../fonts/cera_round_pro_black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cera Round Pro Light';
  src: url('../fonts/cera_round_pro_light-webfont.woff2') format('woff2'),
    url('../fonts/cera_round_pro_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cera Round Pro Medium';
  src: url('../fonts/cera_round_pro_medium-webfont.woff2') format('woff2'),
    url('../fonts/cera_round_pro_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cera Round Pro Regular';
  src: url('../fonts/cera_round_pro_regular-webfont.woff2') format('woff2'),
    url('../fonts/cera_round_pro_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cera Round Pro Thin';
  src: url('../fonts/cera_round_pro_thin-webfont.woff2') format('woff2'),
    url('../fonts/cera_round_pro_thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
